import React from 'react';
import {ThemeProvider} from 'styled-components';
import {Helmet} from "react-helmet";
import {ToastContainer} from "react-toastify";
import {appTheme as theme, GlobalStyle} from './theme';
import NovaSenha from './views/login';

function App() {
  return (
    <ThemeProvider theme={{...theme}}>
      <Helmet>
        <html lang="pt-BR" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,400;0,500;0,600;0,700;0,900;1,500&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;600;700;900&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;400;500;600;700;900&display=swap" rel="stylesheet" />
      </Helmet>
      <GlobalStyle />
      <NovaSenha />
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
