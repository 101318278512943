import theme from 'styled-theming';
import {LIGHT} from '../constants/theme';
import {LIGHT_COLORS} from './colors';
import {space} from './space';
import {typography} from './typography';

const colorKeys = Object.keys(LIGHT_COLORS);

const colors = {};
colorKeys.forEach(key => {
  colors[key] = theme('mode', {
    [LIGHT]: LIGHT_COLORS[key],
  });
});

const anunciosBoxSuperiorMaxHeight = '286px';
const anunciosBoxSuperiorMaxHeightMd = '215px';

export const appTheme = {
  // General
  mode: LIGHT,

  // Typography
  ...typography,

  // Space
  space,

  // Sizes & Spacing
  sidebarWidth: '80px',
  toolbarHeight: '70px',
  boxBorderRadius: '3px',
  anunciosBoxSuperiorMaxHeightMd,
  contentHeight: `calc(100vh - 90px - 1rem - 32px)`,
  contentHeightMd: `calc(100vh - 55px - 1rem - 8px)`,
  campanhasDashboardHeight: `calc(100vh - 60px - 32px - 2rem - 15px - 100px)`,
  campanhasDashboardHeightMd: `calc(100vh - 85px - 8px - 2rem - 15px - 125px)`,
  cidadesListDashboardHeight: `calc(100vh - 90px - 32px - 2rem - 8px - 210px)`,
  cidadesListDashboardHeightMd: `calc(100vh - 35px - 8px - 2rem - 8px - 300px)`,
  mapaUltimasLocalizacoesMotorista: `calc(100vh - 400px)`,
  mapaUltimasLocalizacoesMotoristaMd: `calc(100vh - 300px)`,
  motoristasPorCampanhaListHeight: `calc(100vh - 380px)`,
  motoristasPorCampanhaListHeightMd: `calc(100vh - 330px)`,
  mapaPaginaAnunciosHeight: `calc(100vh - ${anunciosBoxSuperiorMaxHeight} - 32px - 90px - 2rem - 32px)`,
  mapaPaginaAnunciosHeightMd: `calc(100vh - ${anunciosBoxSuperiorMaxHeightMd} - 8px - 35px - 2rem - 8px)`,
  anunciosListHomeHeight: `calc(100vh - 284px - 32px - 90px - 2rem - 32px)`,
  anunciosListHomeHeightMd: `calc(100vh - 284px - 8px - 35px - 2rem - 8px)`,
  anunciosListHeight: `calc(100vh - ${anunciosBoxSuperiorMaxHeight} - 32px - 90px - 2rem - 32px)`,
  anunciosListHeightMd: `calc(100vh - ${anunciosBoxSuperiorMaxHeightMd} - 8px - 35px - 2rem - 8px)`,

  // Misc
  transitionEase: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',

  // Colors
  colors: {...colors},

  styledScroll: {
    '&::-webkit-scrollbar': {
      width: '4px',
      marginLeft: '5px'
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(101, 116, 150, 0.15)'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(101, 116, 150, 0.25)',
      borderRadius: '50px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(15, 54, 108, 0.6)'
    }
  }
};
