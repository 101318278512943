import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    justify-content: flex-start;
    padding-left: 130px;
    padding-right: 100px;
  }
`;

const FaleComSuporteLink = styled.a`
  color: #0083e6;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
`;

const Footer = () => {
  const params = new URLSearchParams(window.location.search);
  const lng = params.get('lng') || 'pt';
  return (
    <Container>
      <FaleComSuporteLink href='https://wa.me/554430136078' target="_blank">
      {lng === 'en' ? 'Contact KM Mídia Support' : 'Fale com o Suporte KM Mídia'}
      </FaleComSuporteLink>
    </Container>
  );
};

export default Footer;
