import {normalize} from 'polished';
import {createGlobalStyle} from 'styled-components';
import AktivGrotesk from './fonts/AktivGrotesk-Regular.ttf';
import AktivGroteskLight from './fonts/AktivGrotesk-Light.ttf';
import AktivGroteskMedium from './fonts/AktivGrotesk-Medium.ttf';
import AktivGroteskBold from './fonts/AktivGrotesk-Bold.ttf';
import AktivGroteskXBold from './fonts/AktivGrotesk-XBold.ttf';

export const GlobalStyle = createGlobalStyle`
  ${normalize()}

  @font-face {
    font-family: 'AktivGrotesk';
    src: url(${AktivGrotesk}) format('truetype');
  }

  @font-face {
    font-family: 'AktivGrotesk-Regular';
    src: url(${AktivGrotesk}) format('truetype');
  }

  @font-face {
    font-family: 'AktivGrotesk-Light';
    src: url(${AktivGroteskLight}) format('truetype');
  }

  @font-face {
    font-family: 'AktivGrotesk-Medium';
    src: url(${AktivGroteskMedium}) format('truetype');
  }

  @font-face {
    font-family: 'AktivGrotesk-Bold';
    src: url(${AktivGroteskBold}) format('truetype');
  }

  @font-face {
    font-family: 'AktivGrotesk-XBold';
    src: url(${AktivGroteskXBold}) format('truetype');
  }

  * {
    box-sizing: border-box;
    outline: none;
  }
  
  html, body, #root {
    height: 100%;
    background: ${props => props.theme.colors.background};
    /* font-family: 'AktivGrotesk', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
    font-family: AktivGrotesk, serif;
  }
  
  body {
    font-size: 16px;
    line-height: 1.5;
    color: ${props => props.theme.text};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  .map-container {
    height: 100%;
    width: 100%;
  }
  .map-ref {
    height: 100%;
  }
  .gm-style .gm-style-iw {
    background: none;
    border-radius: 0;

    & > button {
      display: none !important;
    }
  }
  .gm-style .gm-style-iw-t::after {
    background: #657496;
  }
  .gm-style .gm-style-iw-d {
    overflow: hidden !important;
  }
  .gm-style .gm-style-iw-c {
    box-shadow: none;
    padding: 0 !important;
  }

  .iw-condutor-container {
    background-color: #657496;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;

    & .iw-condutor-avatar {
      display: flex;
      margin-right: 15px;
    }

    & .iw-condutor-info {
      & label {
        color: #afb7c9;
        font-size: 12px;
        margin-bottom: 3px;
        display: block;
      }

      & p {
        margin: 0 0 8px;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
`;
