import React from 'react';
import styled from 'styled-components';

export const Button = styled.button`
  height: 48px;
  line-height: 48px;
  border: 0;
  font-size: 14px;
  background-color: #0083e6;
  color: #ffffff;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ButtonOutline = styled(Button)`
  border: solid 1px #0083e6;
  background-color: #fff;
  color: #0083e6;
`;

export const ButtonComponent = ({value, leftIcon, rightIcon, ...props}) => (
  <Button {...props}>
    {leftIcon}
    {value}
    {rightIcon}
  </Button>
);

export const ButtonOutlineComponent = ({
  value,
  leftIcon,
  rightIcon,
  ...props
}) => (
  <ButtonOutline {...props}>
    {leftIcon}
    {value}
    {rightIcon}
  </ButtonOutline>
);
